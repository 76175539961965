<template>
    <section class="mt-10">
        <LineChart :chartData="chartData" :chartOptions="chartOptions" :styles="chartStyles" />
    </section>
    <section class="mt-5">
        <div class="grid grid-cols-12 gap-x-6 gap-y-3">
            <div class="col-span-6">
                <table class="divide-y divide-fibonaki-border">
                    <thead>
                        <tr>
                            <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900 w-1/6">{{ $tc('month', 1) }}</th>
                            <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900 w-1/6">{{ new Date().getFullYear() - 3 }}</th>
                            <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900 w-1/6">{{ new Date().getFullYear() - 2 }}</th>
                            <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900 w-1/6">{{ new Date().getFullYear() - 1 }}</th>
                            <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900 w-1/6">{{ new Date().getFullYear() }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="index in 12" :key="index">
                            <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{{ months[index - 1] }}</td>
                            <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{{ ElecMonthlyEpexSpotDataY3[index - 1] ? (ElecMonthlyEpexSpotDataY3[index - 1].average === '' ? '' : $filters.getHumanPrice(ElecMonthlyEpexSpotDataY3[index - 1].average, 2)) : '' }}</td>
                            <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{{ ElecMonthlyEpexSpotDataY2[index - 1] ? (ElecMonthlyEpexSpotDataY2[index - 1].average === '' ? '' : $filters.getHumanPrice(ElecMonthlyEpexSpotDataY2[index - 1].average, 2)) : '' }}</td>
                            <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{{ ElecMonthlyEpexSpotDataY1[index - 1] ? (ElecMonthlyEpexSpotDataY1[index - 1].average === '' ? '' : $filters.getHumanPrice(ElecMonthlyEpexSpotDataY1[index - 1].average, 2)) : '' }}</td>
                            <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{{ ElecMonthlyEpexSpotDataY0[index - 1] ? (ElecMonthlyEpexSpotDataY0[index - 1].average === '' ? '' : $filters.getHumanPrice(ElecMonthlyEpexSpotDataY0[index - 1].average, 2)) : '' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-span-1"></div>
            <div class="col-span-3">
                <table class="divide-y divide-fibonaki-border">
                    <thead>
                        <tr>
                            <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900 w-1/6">{{ $tc('period', 1) }}</th>
                            <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900 w-1/6">{{ $tc('value', 1) }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr >
                            <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{{ $tc('average_last_month', 1) }}</td>
                            <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{{ $filters.getHumanPrice(averages.last_month_average, 2) }}</td>
                        </tr>
                        <tr >
                            <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{{ $tc('average_last_year', 1) }}</td>
                            <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{{ $filters.getHumanPrice(averages.last_year_average, 2) }}</td>
                        </tr>
                        <tr >
                            <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{{ $tc('average_last_year', 3) }}</td>
                            <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{{ $filters.getHumanPrice(averages.last_3_year_average, 2) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>

<script>
    import LineChart from '@/components/chart/LineChart';
    import moment from 'moment';

    export default {
        components: {
            LineChart,
        },
        created() {
            this.$store.dispatch('energy_data/fetchElecEpexSpotMonthlyData');
        },
        data() {
            return {
                chartStyles: {
                    height: '300px',
                },
                chartOptions: {
                    animation: { duration: 0 },
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: true,
                    },
                    tooltips: {
                        titleFontSize: 24,
                        bodyFontSize: 20,
                    },
                    scales: {
                        xAxes: [
                            {
                                gridLines: { display: false },
                                ticks: {
                                    fontSize: 10,
                                },
                            },
                        ],
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: false,
                                    callback: function (value) {
                                        return '€' + ' ' + value;
                                    },
                                },
                            },
                        ],
                    },
                },
                months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            };
        },
        computed: {
            chartData() {
                return {
                    labels: this.$store.getters['energy_data/elec_monthly_epex_spot_chart'].epex_spot_monthly_labels,
                    datasets: [
                        {
                            label: 'EPEX Spot',
                            fill: true,
                            backgroundColor: 'hsla(204, 68%, 58%, 0.25)',
                            pointRadius: 0,
                            borderWidth: 1,
                            borderColor: '#4ca3dd',
                            data: this.$store.getters['energy_data/elec_monthly_epex_spot_chart'].epex_spot_monthly_values,
                        },
                    ],
                };
            },
            ElecMonthlyEpexSpotData() {
                return this.$store.getters['energy_data/elec_monthly_epex_spot_data'];
            },
            ElecMonthlyEpexSpotDataY0() {
                return this.$store.getters['energy_data/elec_monthly_epex_spot_data_y0'];
            },
            ElecMonthlyEpexSpotDataY1() {
                return this.$store.getters['energy_data/elec_monthly_epex_spot_data_y1'];
            },
            ElecMonthlyEpexSpotDataY2() {
                return this.$store.getters['energy_data/elec_monthly_epex_spot_data_y2'];
            },
            ElecMonthlyEpexSpotDataY3() {
                return this.$store.getters['energy_data/elec_monthly_epex_spot_data_y3'];
            },
            averages() {
                return this.$store.getters['energy_data/elec_monthly_epex_spot_chart'];
            },
        },
        methods: {
            getYearMonth(year, index) {
                return moment(year + '-' + (index - 1))
                    .format('YYYY-MM')
                    .toString();
            },
        },
    };
</script>
